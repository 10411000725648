import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState, useRef } from "react";
import useLogin from "../../hooks/useLogin.hk";
import { useHistory } from "react-router";
import { setPageTitle } from "../../utils/page";
import brace from 'brace';
import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/theme-github";
import 'brace/mode/xml';
import 'brace/theme/github';
//import "ace-builds/webpack-resolver";
// import "ace-builds/src-noconflict/mode-xml";

import { useModal } from "../../hooks/useModal.hk";
import { isXmlValid } from "../../utils/xml";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./edit.pg.scss";
import { getXml, saveXml } from "../../service";

const options = [
	{ label: "Utenti", value: "users" },
	{ label: "Video", value: "videos" },
];

const PgEdit = () => {
	const history = useHistory();
	const [user, logout] = useLogin();


	const [selectedOption, setSelectedOption] = useState();

	useEffect(() => {
		setSelectedOption(options[0].value)
	}, []);

	const aceEditorRef = useRef();

	const [xml, setXml] = useState("");

	const { modal, showModal } = useModal();

	useEffect(() => {
		if (!modal.show) {
			setFocus();
		}
	}, [modal.show]);

	useEffect(() => {
		setPageTitle("Edit");
	}, []);

	useEffect(() => {

		switch (selectedOption) {
			case "users":
				getXml(user.token, selectedOption)
					.then(res => {
						if (res.data.errorMsg === "token expired") {
							logout(); return;
						} else if (res.data.status === "OK") {
							setXml(res.data.xml);
						} else {
							setXml(res.data.xml);
						}
					});
				break;
			case "videos":
				getXml(user.token, selectedOption)
					.then(res => {
						if (res.data.errorMsg === "token expired") {
							logout(); return;
						} else if (res.data.status === "OK") {
							setXml(res.data.xml);
						} else {
							setXml(res.data.xml);
						}
					});
				break;
			default:
				break;
		}

		aceEditorRef.current.editor.$blockScrolling = Infinity;

	}, [selectedOption]);

	const setFocus = () => {
		try {
			aceEditorRef.current.editor.focus();
		} catch (e) {

		}
	}

	const handleSave = () => {
		if (selectedOption !== "" && xml !== "") {
			let checkXml = isXmlValid(xml);
			if (!checkXml.success) {
				showModal("Errore", checkXml.msg);
				setFocus();
				return;
			}
			const params = new URLSearchParams()
			params.append('action', "savexml")
			params.append('type', selectedOption)
			params.append('token', user.token)
			params.append('xml', xml)

			saveXml(user.token, selectedOption, xml)
				.then(res => {
					if (res.data.errorMsg === "token expired") {
						logout(); return;
					} else if (res.data.status === "OK") {
						toast.success('Xml salvato', {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: false,
							progress: undefined,
						});
					} else {
						showModal("Errore", "Si è verificato un errore durante il salvataggio");
					}
					setFocus();
				});
		}
	}

	return <div className="pg-edit">
		{/* <TestModal message={modal.message} isOpen={modal.isOpen} /> */}
		<div className="container">
			<div className="edit-container">
				<div className="d-flex">
					<h1 className="title mb-0 mr-auto">Editor XML</h1>
					<button className="videos-btn" onClick={() => history.push("/videos")}>
						Torna ai video
						<FontAwesomeIcon icon="video" className="ml-2" />
					</button>
				</div>
				<hr />
			</div>
			<div className="row row-2">
				<div className="col-12">
					<label htmlFor="drop-xml" >Seleziona xml</label>
					<select id="drop-xml" className="form-control form-control-sm" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
						{options.map((option, index) => (
							<option key={index} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
					<button className="btn btn-sm btn-success btn-save" onClick={handleSave}>Salva</button>
				</div>
				<div className="col-8">

				</div>
			</div>
			<div className="row row-2">
				<div className="col-12">
					<AceEditor
						ref={aceEditorRef}
						placeholder=""
						mode="xml"
						theme="github"
						name="ace-editor"
						fontSize={16}
						showPrintMargin={true}
						showGutter={true}
						highlightActiveLine={true}
						value={xml}
						onChange={(value) => setXml(value)}
						setOptions={{
							useWorker: false,
							// enableBasicAutocompletion: false,
							// enableLiveAutocompletion: false,
							// enableSnippets: false,
							showLineNumbers: true,
							tabSize: 2,
						}}
					/>
				</div>
			</div>
		</div>
		<ToastContainer />
	</div>;
};

export default PgEdit;