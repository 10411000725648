import { useSelector } from "react-redux";
import useLogin from "./useLogin.hk";
import { writeLog, writeLogAsBecon } from "../service";

const logSomething = (token, info, tag, status) => {
	return writeLog(token,info,tag,status);
}

const logAsBeacon = (token, info, tag, status) => {
	writeLogAsBecon(token, info, tag, status);
}

const useLog = () => {
	const video = useSelector(state => state.video);
	const [uData] = useLogin();
	const vProg = video.videoProgress;

	const isAdmin = uData != null ? uData.isAdmin : false;

	const logUserLogin = () => logSomething(uData?.token, "login eseguito", "login", "success");
	const logVideoEnd = () => logSomething(uData?.token, { videoId: vProg.info.videoId }, "videoend", "success");

	const logVideoProg = (asBeacon = false) => asBeacon ?
		logAsBeacon(uData?.token, vProg.info, vProg.tag, vProg.status) :
		logSomething(uData?.token, vProg.info, vProg.tag, vProg.status);

	return [logUserLogin, logVideoProg, logVideoEnd];
};

export default useLog;