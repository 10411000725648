import { useDispatch, useSelector} from "react-redux";
import { setModal } from "../store/features/ModalSlice";

export const useModal = () => {
	const dispatch = useDispatch();
	const modal = useSelector(state => state.modal);

	const showModal = (title, content) => {
		dispatch(setModal({title: title, content: content, show: true}));
	};

	const hideModal = () => {
		dispatch(setModal({show: false }));
	};
	return {modal, showModal, hideModal};
}

