import { getPercentViewTime } from "../store/features/VideoSlice";
import { msToTime } from "../utils/date";
import "./videoProgress.cmp.scss";

/*
info: {
	viewMatrix: number[],
	title: string,
	totalTime: number
}
*/

const CmpVideoProgress = ({ info, video, barWidth = 3, showPopover = true }) => {
	const totWidth = 100 * barWidth;
	let matrix = info.viewMatrix;
	let ranges = info.ranges;
	const title = info.title ?? video?.title;

	// Ritorno il titolo e via se non ho abbastanza info
	if (!matrix && !ranges && !video) return <h5>{title}</h5>;

	let progress;

	if (!ranges)
	{
		if (!matrix)
		{
			const percent = getPercentViewTime(video, info.totalTime);
			matrix = [...Array(Math.min(percent, 100) + 1).keys()];
		}

		if (matrix.length < 1) { matrix.push(0); }
		progress = matrix.length - 1;
	}
	else
	{
		const totTime = ranges.reduce((tot, r) => tot + (r[1] - r[0]), 0);
		progress = getPercentViewTime(video, totTime);
	}

	const bars = <div className = "vp-bars-wrapper" style = {{ width: `${totWidth}px` }}>
		{
			ranges?
			ranges.map(r => {
				const cMult = totWidth / info.videoLength;
				const offset = r[0] * cMult;
				const width = (r[1] - r[0]) * cMult;
				return <div key = {`${offset}_${width}`} className = "vp-bar range" style = {{ left: `${offset}px`, width: `${width}px` }} title = {showPopover? `${msToTime(r[0])} - ${msToTime(r[1])}` : null}/>
			})
			:
			// [...Array(n).keys()] per creare un array con tutti i numeri da 0 a n-1
			// Mi piace, è elegante
			[...Array(100).keys()].map(i => {
				const viewed = matrix.indexOf(i + 1) > -1;
				return <div key = {i} className = {`vp-bar${viewed? " viewed" : ""}`} style = {{ left: `${i * barWidth}px`, width: `${barWidth}px` }}/>
			})
		}

		<div className = "vp-percent">
			{progress}%
		</div>
	</div>

	return <div className = "cmp-video-progress">
		{title? <h5>{title}</h5> : null}

		{bars}
	</div>;
}

export default CmpVideoProgress;