import CmpVideoLink from "./videoLink.cmp";
import "./videoList.cmp.scss";

const CmpVideoList = ({ videoList }) => {
	return <div className = "cmp-video-list">
		<div className = "row">
			{videoList.map(v => <CmpVideoLink key = {v.videoId} video = {v}/>)}
		</div>
	</div>
}

export default CmpVideoList;