import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import useLog from "../../hooks/useLog.hk";
import useLogin from "../../hooks/useLogin.hk";
import { setPageTitle } from "../../utils/page";
import {googleAnalyticsTracker} from "../../utils/google"
import "./login.pg.scss";

const PgLogin = () => {
    const history = useHistory();

    const [uName, setUName] = useState("");
    const [pw, setPw] = useState("");
    const [pwVisible, setPwVisible] = useState(false);
    const [state, setState] = useState({ loading: false, error: "" });
    const [user, login] = useLogin();
	const [logUser] = useLog();

    const handleSubmit = event => {
        event.preventDefault();
        setState(s => ({ ...s, loading: true }));

        login(uName, pw).then(res => {
            if (res.status !== "OK") {
				googleAnalyticsTracker("page", "login", uName);
				setState({ loading: false, error: true });
			}
        });
    }

	useEffect(() => {
		if (user) {
			logUser();
			history.push("/videos");
		}
	}, [user, history, logUser]);

	useEffect(() => {
		setPageTitle("Login");
	})

    return <div className = "pg-login">
		<div className = "container">
			<div className = "login-container">
				<form onSubmit = {handleSubmit} className = {"login-form" + (state.loading? " disabled" : "")}>
					<h1 className = "login-title">Login</h1>

					<label htmlFor = "user" className = "login-user">
						<FontAwesomeIcon className = "mx-1" icon = "user"/>
						<input id = "user"
							onChange = {event => setUName(event.target.value)}
							value = {uName}
							placeholder = "Username"
							autoComplete = "off"
							required/>
					</label>
					
					<label htmlFor = "pw" className = "login-pw">
						<FontAwesomeIcon className = "mx-1" icon = "lock"/>
						<input id = "pw"
							type = {pwVisible? "text" : "password"}
							onChange = {event => setPw(event.target.value)}
							value = {pw}
							placeholder = "Password"
							autoComplete = "off"
							required/>
						<button onClick = {() => setPwVisible(!pwVisible)} type = "button">
							<FontAwesomeIcon icon = {pwVisible? "eye-slash" : "eye"}/>
						</button>
					</label>

					<hr className = "login-hr"/>
					<button className = "login-submit" type = "submit">Login</button>
					{
						state.error?
						<div className = "alert alert-danger border-danger mt-3 mb-0">
							Dati di login errati
						</div> : null
					}
				</form>
			</div>
		</div>
    </div>;
};

export default PgLogin;