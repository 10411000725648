import { createSlice } from "@reduxjs/toolkit";
import { getSessionStorage } from "../../hooks/useSessionStorage.hk";

//const [ssHtml] = getSessionStorage("_bw6_remote_html");
const initHtml = {
	header: "",
	footer: "",
	loaded: false
};

const UISlice = createSlice({
    name: "UI",
    initialState: initHtml,
    reducers: {
        setHTML: (state, action) => {
            state.header = action.payload.header;
            state.footer = action.payload.footer;
            state.loaded = action.payload.loaded;
        }
    }
});

export const { setHTML } = UISlice.actions;
export default UISlice.reducer;