const useSessionStorage = (key, initial, isObject = true) => {
	const [stored, strValue] = getSessionStorage(key, isObject, initial);

	// O così o non ne vengo fuori
	const setStored = (obj, updateIfNull = false) => {
		if (!updateIfNull && obj == null) return;
		sessionStorage.setItem(key, isObject? JSON.stringify(obj) : obj);
	}

    return [stored, setStored];
}

const getSessionStorage = (key, asObject = true, initial = null) => {
    const temp = sessionStorage.getItem(key);
	const stored = temp === null? initial : (asObject? JSON.parse(temp) : temp);

	return [stored, temp];
}

export default useSessionStorage;
export { getSessionStorage };