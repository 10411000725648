import { BrowserRouter } from 'react-router-dom';
import { Redirect, Route } from 'react-router';
import PrivateRoute from './components/privateRoute';

import PgLogin from './pages/login/login.pg';
import PgVideos from './pages/videos/videos.pg';
import PgUserLogs from './pages/users/users.pg';
import PgEdit from './pages/edit/edit.pg';

// import { useRemoteHtml } from './hooks/useRemoteHtml';
import { useSelector, useDispatch } from 'react-redux';

import Header from './components/header.cmp';
import Footer from './components/footer.cmp';

import { library } from '@fortawesome/fontawesome-svg-core';
import * as allIcons from '@fortawesome/free-solid-svg-icons';
import Preloader from './components/preloader.cmp';
import PgUserDetails from './pages/users-detail/users-detail.pg';
import { Modal } from './components/modal.cmp';
import './styles/bootstrap.min.css';
import './styles/fonts/metric/metric.min.css';
import './App.scss';
import { useAppConfig } from './hooks/useAppConfig';
import GoogleAnalytics from './components/googleAnalytics.cmp';
import { useEffect } from 'react';
import { getAppConfig } from './service';
import { setAppConfig } from './store/features/AppConfig';
import { setPrefixPageTitle } from './utils/page';
import { setHTML } from './store/features/UISlice';

library.add(
	Object.keys(allIcons)
		.filter(i => i !== "fas" && i !== "prefix")
		.map(i => allIcons[i])
);



const App = () => {

	const loaded = useSelector(state => state.UI.loaded);

	useAppConfig();

	return  <>
			{renderIfLoaded(loaded,
				<BrowserRouter>
					<Header />
					<Route exact path="/">
						<Redirect to="/videos" />
					</Route>
					<Route path="/login" component={PgLogin} />
					<PrivateRoute path="/videos" redirectTo="/login">
						<PgVideos />
					</PrivateRoute>
					<PrivateRoute path="/users" exact redirectTo="/videos" adminOnly={true}>
						<PgUserLogs />
					</PrivateRoute>
					<PrivateRoute path="/users/:userId" redirectTo="/videos" adminOnly={true}>
						<PgUserDetails />
					</PrivateRoute>
					<PrivateRoute path="/edit/" redirectTo="/videos" adminOnly={true}>
						<PgEdit />
					</PrivateRoute>
					<Footer />
					<Modal />
					<GoogleAnalytics />
				</BrowserRouter>			
		)}
	</>
};

const renderIfLoaded = (isLoaded, content) =>
	isLoaded ?
		content : <Preloader />;

export default App;
