export const removeDuplicates = (array, keyTfFunc = i => i) => {
	const res = array.reduce((aux, item) => {
		let key = keyTfFunc(item);

		if (aux[key] === undefined) {
			aux[key] = item;
		}

		return aux;
	}, {});

	return Object.keys(res).map(k => res[k]);
}

