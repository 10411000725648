import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useLog from "../hooks/useLog.hk";
import { videoPlay } from "../store/features/VideoSlice";
import { googleAnalyticsTracker } from "../utils/google";
import { getHost } from "../utils/host";
import "./videoLink.cmp.scss";

const CmpVideoLink = ({ video, cssClass = "col-12 col-sm-6 col-lg-4" }) => {
	const [, logVideo] = useLog();
	const videoData = useSelector(state => state.video);
	const username = useSelector(state => state.user.username);
	const dispatch = useDispatch();

	return <div className = {`cmp-video-link ${cssClass} ${videoData.currVideo?.videoId == video.videoId? "current" : ""}`}>
		<button onClick = {handleVideoChange(videoData.videoProgress, logVideo, video, dispatch, username)}>
			<img src = {`${getHost()}${video.thumb}`} alt = ""/>
			<div className = "overlay">
				<FontAwesomeIcon icon = "play-circle"/>
				<div className = {`video-title ${getClass(video, videoData?.currVideo?.videoId)}`}>
					<span>{video.title} [{video.duration}]</span>
				</div>
			</div>
		</button>
	</div>
}

const getClass = (video, currVideoId) => video.videoId == currVideoId? "active" : "";

const handleVideoChange = (vProg, logVideo, video, dispatch, username) => () =>
	new Promise(() => {
		if (vProg.info.ranges.length > 0 && !vProg.videoEnded && !vProg.paused) {
			logVideo();
			googleAnalyticsTracker("videoplay", JSON.stringify(vProg.info), username);
		}
	})
	.then(dispatch(videoPlay(video)));

export default CmpVideoLink;