import { createSlice } from "@reduxjs/toolkit";

const defaultParams =  {
	title: "",
	content: "",
	show: false
};

const ModalSlice = createSlice({
    name: "modal",
    initialState: defaultParams,
    reducers: {
        setModal: (state, action) => {
            state.title = action.payload.title ?? "";
            state.content = action.payload.content ?? "";
            state.show = action.payload.show ?? false;
        },
    }
});

export const { setModal } = ModalSlice.actions;
export default ModalSlice.reducer;