import { useSelector } from "react-redux";
import "./videoInfo.cmp.scss";

const CmpVideoInfo = () => {
	const video = useSelector(state => state.video.currVideo);

	return video? <div className = "cmp-video-info">
		<h5 className = "title">{video.title}</h5>
		<span dangerouslySetInnerHTML = {{__html: video.description}}/>
	</div> : null
};

export default CmpVideoInfo;