export const stringToDate = (dString, withTime = true) => {
	if (dString instanceof Date) {
		return dString;
	}

	const [date, time] = dString.split(" ");
	const [dd, MM, yyyy] = (date || "").split("/");
	const [HH, mm, ss] = (time || "").split(":");

	const res = new Date(parseInt(yyyy), parseInt(MM) - 1, parseInt(dd));
	if (withTime) { res.setHours(parseInt(HH), parseInt(mm), parseInt(ss)); }

	return res;
};


const addZero = (val) => (val < 10 ? "0" : "") + val;

export const msToTime = ms => {
	const totSS = Math.floor(ms / 1000);
	const SS = totSS % 60;
	const MM = Math.floor(totSS / 60) % 60;
	const HH = Math.floor(totSS / 3600);

	return `${addZero(HH)}:${addZero(MM)}:${addZero(SS)}`;
}