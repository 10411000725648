import { Redirect, Route } from "react-router";
import useLogin from "../hooks/useLogin.hk";

const PrivateRoute = ({children, redirectTo, adminOnly = false, ...props}) => {
	const [user] = useLogin();

	return <Route {...props} render = {() =>
		user && (adminOnly? user.isAdmin : true)? children :
		<Redirect to = {redirectTo}/>
	}/>
}

export default PrivateRoute;