import axios from "axios";
import { getHost } from "../utils/host";

export const getServiceUrl = () => {
    return `${getHost()}/service.ashx`;
}

export const login = (name, pw) => {

    const params = new URLSearchParams()
    params.append('action', "login");
    params.append('username', name);
    params.append('password', pw);

    return axios.post(
        getServiceUrl(),
        params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}

export const getAppConfig = () => {
    const params = new URLSearchParams()
    params.append('action', "getappconfig");
    //params.append('url', url);
    return axios.get(getServiceUrl(), { params });
}

// export const getHtml = () => {

//     const params = new URLSearchParams()
//     params.append('action', "gethtml");
//     //params.append('url', url);
//     return axios.get(getServiceUrl(), { params });
// }

export const writeLog = (token, info, tag, status) => {
	const strInfo = typeof info === "object" ? JSON.stringify(info) : info;

    const params = new URLSearchParams();
    params.append('action', "writelog");
    params.append('token', token);
    params.append('info', strInfo);
    params.append('tag', tag);
    params.append('status', status);

    return axios.post(
        getServiceUrl(),
        params, 
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )
}

export const writeLogAsBecon = (token, info, tag, status) => {
    const strInfo = typeof info === "object" ? JSON.stringify(info) : info;
	navigator.sendBeacon(`${getServiceUrl()}?action=writelog&token=${token}&info=${encodeURIComponent(strInfo)}&tag=${encodeURIComponent(tag)}&status=${encodeURIComponent(status)}`, null);
}

export const getXml = (token, type) => {
    const params = new URLSearchParams();
    params.append('action', "getxml");
    params.append('token', token);
    params.append('type', type);
    return axios.get(getServiceUrl(), { params });
}

export const saveXml = (token, type, xml) => {
    const params = new URLSearchParams();
	params.append('action', "savexml");
	params.append('type', type);
	params.append('token', token);
	params.append('xml', xml);
    return axios.post(
        getServiceUrl(),
	    params, 
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}
    )
}

export const getLoggedUsers = (token, type) => {
    const params = new URLSearchParams();
    params.append('action', "getloggedusers");
    params.append('token', token);
    return axios.get(getServiceUrl(), { params });
}

export const getLogUser = (token, userId) => {
    const params = new URLSearchParams();
    params.append('action', "getuserlog");
    params.append('token', token);
    params.append('id', userId);
    return axios.get(getServiceUrl(), { params });
}

export const getVideos = (token) => {
    const params = new URLSearchParams()
    params.append('action', "getvideos");
    params.append('token', token);
    return axios.get(getServiceUrl(), { params });
}
