import { configureStore } from "@reduxjs/toolkit";
import AppConfig from "./features/AppConfig";
import ModalSlice from "./features/ModalSlice";
import UISlice from "./features/UISlice";
import UserSlice from "./features/UserSlice";
import VideoSlice from "./features/VideoSlice";

export const store = configureStore({
    reducer: {
        AppConfig: AppConfig,
        UI: UISlice,
        user: UserSlice,
        video: VideoSlice,
        modal: ModalSlice
    }
});

export default store;