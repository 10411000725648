import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLog from "../hooks/useLog.hk";
import useLogin from "../hooks/useLogin.hk";
import { videoTogglePause } from "../store/features/VideoSlice";
import { toSerializableVideoElement } from "../store/features/VideoSlice";
import { videoPlay, videoProgress, videoEnd } from "../store/features/VideoSlice";
import { googleAnalyticsTracker } from "../utils/google";
import { getHost } from "../utils/host";
import "./videoPlayer.cmp.scss";

const CmpVideoPlayer = () => {
    const [ , logProgress, logEnd ] = useLog();
    const [ firstPlay, setFirstPlay ] = useState(true);
    const [ showThumb, setShowThumb ] = useState(true);
    const [ disableScroll, setDisableScroll ] = useState(true);
	const video = useSelector(state => state.video.currVideo);
    const videoEl = useRef();
	const videoContainer = useRef();
    const dispatch = useDispatch();
	
	const [ user ] = useLogin();
	const vProgInfo = useSelector(state => state.video.videoProgress.info);

    const handleTimeUpdate = (vElement) => {
        dispatch(videoProgress(toSerializableVideoElement(vElement)));
    }

    const handlePlay = () => {
		if (showThumb) { googleAnalyticsTracker("video", video.title, user.username); }
        setShowThumb(false);
		dispatch(videoTogglePause(false));
        if (!firstPlay) return;
        dispatch(videoPlay(video));
        setFirstPlay(false);
    };

	useEffect(() => {
		setShowThumb(true);

		if (disableScroll && video) {
			setDisableScroll(false);
			return;
		}

		if (videoContainer.current) {
			videoContainer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [video]);

	useEffect(() => {
		const handleUnload = () => {
			const vid = videoEl.current;
			if (vid && vid.played.length > 0 && !vid.paused) {
				googleAnalyticsTracker("videoplay", JSON.stringify(vProgInfo), user.username);
				logProgress(true);
			}
		};

		window.addEventListener("beforeunload", handleUnload);
		return () => window.removeEventListener("beforeunload", handleUnload);
	}, [videoEl.current?.played, logProgress, user.username, vProgInfo]);

    const handlePause = () => {
		dispatch(videoTogglePause(true));
		logProgress();
		googleAnalyticsTracker("videoplay", JSON.stringify(vProgInfo), user.username);
    };
	
    const handleEnd = () => {
		dispatch(videoTogglePause(true));
		dispatch(videoEnd);
		logProgress().then(logEnd);
    };

    return video?
		<div className="cmp-video-player"  ref = {videoContainer}>
			{
				// Thumbnail
				showThumb? <>
					<img className = "img-thumb" src = {`${getHost()}${video.thumb}`} alt = ""/>
					<div className = "overlay" onClick = {() => handlePlay()}>
						<FontAwesomeIcon icon = "play-circle"/>
					</div>
				</> :
				<video ref = {videoEl}
					src = {`${getHost()}${video.url}`}
					onPlay = {handlePlay}
					onLoadStart = {handlePlay}
					onTimeUpdate = {event => handleTimeUpdate(event.target)}
					onEnded = {handleEnd}
					onPause = {handlePause}
					onContextMenu = {event => event.preventDefault()}
					controls controlsList = "nodownload"
					autoPlay>
				</video>
			}
		</div> : null;
};

export default CmpVideoPlayer;