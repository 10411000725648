const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const { default: useLogin } = require("../hooks/useLogin.hk");

const CmpLogoutBtn = ({ text = "Logout" }) => {
	const [,, logout] = useLogin();

	return <button className = "logout-btn" onClick = {() => logout()} title = "Logout">
		{text}
		<FontAwesomeIcon icon = "sign-out-alt" className = "ml-2"/>
	</button>
};

export default CmpLogoutBtn;