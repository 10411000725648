import { createSlice } from "@reduxjs/toolkit";
import { getSessionStorage } from "../../hooks/useSessionStorage.hk";

const [user] = getSessionStorage("_bw6_user_data");

const UserSlice = createSlice({
    name: "user",
    initialState: {
		userData: user
    },
    reducers: {
		login: (state, action) => { state.userData = action.payload; },
		logout: state => { state.userData = null; }
    }
});

export const { login, logout } = UserSlice.actions;
export default UserSlice.reducer;