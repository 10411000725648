import "./dataTable.cmp.scss";

// const execIfFn = (val, ...args) => typeof val == "function"? val(args) : val;

/*
rowKeyFn: item => ID univoco
columns: {
	key: ID univoco
	header: ReactComponent o qualcosa del genere
	cellFn: item => ReactComponent o affini
}
*/

const DataTableCol = (key, header, cellFn) => ({
	key, header, cellFn
});

const CmpDataTable = ({data, columns, rowKeyFn, className}) => {
	const headers = [], cellFns = [];

	columns.map(c => {
		headers.push({ header: c.header, key: c.key });
		cellFns.push({ cellFn: c.cellFn, key: c.key });
	});

	return <table className = {`cmp-data-table${className? (" " + className) : ""}`}>
		<thead>
			<tr>
				{headers.map(h => <th key = {h.key}>{h.header}</th>)}
			</tr>
		</thead>
		<tbody>
			{data.map(item =>
				<tr key = {rowKeyFn(item)}>
					{cellFns.map(cf =>
						<td key = {cf.key}>
							{cf.cellFn(item)}
						</td>
					)}
				</tr>
			)}
		</tbody>
	</table>;
};

export default CmpDataTable;
export { DataTableCol };