import { XMLValidator } from "fast-xml-parser";

export const isXmlValid = (xmlData) => {
	if (typeof (xmlData) == 'undefined' && xmlData == null) {
		return {success: true, msg : "Invalid xml"}
	}

	const result = XMLValidator.validate(xmlData, {
		allowBooleanAttributes: true
	});

	if(result === true) {
		return {success: true, msg : ""}
	}else{
		return {success: false, msg : `<b>code</b>: ${result.err.code}, <b>col</b>: ${result.err.col}, <b>line</b>: ${result.err.line}, <b>msg</b>: ${result.err.msg}` }
	}
}