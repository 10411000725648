import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import CmpLogoutBtn from "../../components/logout-btn.cmp";
import CmpVideoInfo from "../../components/videoInfo.cmp";
import CmpVideoList from "../../components/videoList.cmp";
import CmpVideoPlayer from "../../components/videoPlayer.cmp";
import useLogin from "../../hooks/useLogin.hk";
import { getVideos } from "../../service";
import { videoPlay } from "../../store/features/VideoSlice";
import { setPageTitle } from "../../utils/page";
import { googleAnalyticsTracker }  from "../../utils/google";
import "./videos.pg.scss";

const PgVideos = () => {
	const history = useHistory();
    const [user, , logout] = useLogin();
    const [state, setState] = useState({
		pageTitle: "",
        videos: [],
		expired: false
    })

	const dispatch = useDispatch();

	useEffect(() => {
		setPageTitle("Video");
	});
    
    useEffect(() => {
        if (!user) { history.push("/login"); }
		else {
			googleAnalyticsTracker("page", "videos", user.username);

			getVideos(user.token).then(res => {
				if (res.data.status === "OK") {
					const v = res.data.videos;
					setState({
						videos: v,
						pageTitle: res.data.pageTitle
					});
					googleAnalyticsTracker("page", "videos", user.username);

					if (v.length < 1) return;
					dispatch(videoPlay(v[0]));
				}
				else {
					logout();
				}
			})
			.catch(err => logout());
		}
    }, [user]);
    
    return (
        <div className = "pg-videos">
			<div className = "video-player">
				<div className = "container">
					<div className = "title-wrapper">
						{renderTitleDangerously(state.pageTitle)}

						{renderBtnUsers(user, history)}

						<CmpLogoutBtn/>
					</div>
					
					{
						state.videos.length?
						<div className = "row">
							<div className = "col-12 col-lg-8">
								<CmpVideoPlayer user = {user}/>
							</div>
							<div className = "col-12 col-lg-4">
								<CmpVideoInfo/>
							</div>
						</div> : null
					}
				</div>
			</div>
			<div className = "container">
				<CmpVideoList videoList = {state.videos}/>
			</div>
        </div>
    );
}

const renderTitleDangerously = (html) => {
    return <h1 className = "title mr-auto" dangerouslySetInnerHTML = {{__html: html}}/>
};

const renderBtnUsers = (user, history) =>
	user.isAdmin?
		<>
		<button className = "users-btn mr-3" onClick = {() => history.push("/users")} title = "Log utenti">
		Log utenti
		<FontAwesomeIcon icon = "users-cog" className = "ml-2"/>
		</button> 
		<button className = "xml-btn mr-3" onClick = {() => history.push("/edit")} title = "Edit xml">
		Edit xml
		<FontAwesomeIcon icon = "edit" className = "ml-2"/>
		</button> 
		</>

	:
	null;

export default PgVideos;