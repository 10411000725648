import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import CmpDataTable from "../../components/dataTable.cmp";
import { DataTableCol } from "../../components/dataTable.cmp";
import CmpVideoProgress from "../../components/videoProgress.cmp";
import useLogin from "../../hooks/useLogin.hk";
import { getLogUser } from "../../service";
import { getTotalRangesTime } from "../../store/features/VideoSlice";
import { matrixToRanges } from "../../store/features/VideoSlice";
import { mergeRanges } from "../../store/features/VideoSlice";
import { getVideoDurationMs } from "../../store/features/VideoSlice";
import { msToTime, stringToDate } from "../../utils/date";
import { removeDuplicates } from "../../utils/array";

import { setPageTitle } from "../../utils/page";

import "./users-detail.pg.scss";

const emptyState = () => ({
	logs: [],
	user: {},
	videos: {},
});

// Table definitions
const colsView = state => [
	DataTableCol("vis", "Visualizzazioni", v => <div className = "tag">{v.views}</div>),
	DataTableCol("tit", "Titolo", v => v.title),
	DataTableCol("mat", "Progresso", v => {
		const info = {
			ranges: v.ranges,
			viewMatrix: v.viewMatrix,
			videoLength: v.videoLength,
			totalTime: v.totalTime
		};
		
		return <CmpVideoProgress info = {info} video = {state.videos[v.id]} showPopover = {v.showPopover}/>
	}),
	DataTableCol("time", "Tempo totale", v => msToTime(v.totalTime))
];

const colsLog = state => [
	DataTableCol("time", "Time", log => log.time),
	DataTableCol("tag", "Tag", log => <div className = "tag">{log.tag}</div>),
	DataTableCol("info", "Info", log => displayInfo(log, state.videos[log.info.videoId]))
];



const PgUserDetails = () => {
	const params = useParams();
	const [user,, logout] = useLogin();
	const [state, setState] = useState(emptyState());
	const history = useHistory();

	useEffect(() => {
		setPageTitle("Dettaglio log utente");
		if (params.userId) {
			getLogUser(user.token, params.userId)
			.then(res => {
				if (res.data.errorMsg === "token expired") { logout(); return; }

				if (res.data.status === "OK") {
					let newState = emptyState();
	
					res.data.videos.map(v => newState.videos[v.videoId] = v);
					newState.user = res.data.user;
	
					let logs = res.data.logs
					.map((lu, index) => {
						lu.key = index;
						try { lu.info = JSON.parse(lu.info); }
						catch(e) {  }
	
						return lu;
					});

					setPageTitle(`Dettaglio log utente ${newState.user.username}`);
	
					newState.logs = Object.values(logs);
					setState(newState);
				}
				else { /* Mostrare errore */ }
			});
		}
	}, []);

	let views = calcViews(state.logs, state.videos);

	return <div className = "pg-users-detail">
		<div className = "users-detail-container">
			<h1>
				<button className = "back" onClick = {() => history.push("/users")} title = "Indietro">
					<FontAwesomeIcon icon = "arrow-left"/>
				</button>
				{state.user.username} - {state.user.type} [ID: {state.user.id}]
			</h1>
			<hr/>

			<div className = "w-100 d-flex">
				<h2 className = "user-heading mr-4">Tempo totale: {msToTime(calcTotTime(views))}</h2>
				<h2 className = "user-heading">Login: {state.logs.filter(l => l.tag == "login").length}</h2>
			</div>
			<hr/>
			
			<h2 className = "user-heading mb-4">Visualizzazione video</h2>
			<CmpDataTable data = {views} rowKeyFn = {v => v.id} columns = {colsView(state)}/>
			<hr/>
			
			<div class = "mb-4 d-flex align-items-center">
				<h2 className = "user-heading">Log</h2>
				<button className = "ml-auto" onClick = {() => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}>
					<FontAwesomeIcon icon = "angle-down" className = "mr-2"/>Fondo pagina
				</button>
			</div>
			<CmpDataTable data = {groupLogs(state.logs.reverse())} rowKeyFn = {log => log.key} columns = {colsLog(state)}/>
		</div>
	</div>;
};



// ##### VISUALIZZAZIONE #####
const calcTotTime = views => views.reduce((tot, v) => tot + v.totalTime, 0)

const calcViews = (logs, videos) => {
	let logsVideo = logs.filter(log => log.tag === "videoplay" && log.info.videoId);
	let logsOld = logsVideo.filter(log => !log.info.ranges);
	let logsNew = logsVideo.filter(log => log.info.ranges);



	// ### Raggruppa i log vecchi (quelli con viewMatrix e / o totalTime)
	let viewsOld = logsOld
	.reduce((grouped, log, i, arr) => {
		const info = log.info;
		let vLength = getVideoDurationMs(videos[info.videoId]);

		// Crea una viewMatrix continua per quelli che hanno solo totalTime
		if (info.viewMatrix == null) {
			info.viewMatrix = [...Array(Math.floor((info.totalTime / vLength) * 100) + 1).keys()];
		}

		// A volte totalTime è vuoto
		info.totalTime = info.totalTime || 0;

		let last = grouped[info.videoId];

		if (!last) {
			grouped[info.videoId] = {
				views: 1,
				id: info.videoId,
				title: `${videos[info.videoId].title} [ID: ${info.videoId}]`,
				viewMatrix: info.viewMatrix,
				totalTime: info.totalTime,
				videoLength: vLength,
				showPopover: false
			};
		}
		else {
			last.views++;
			last.totalTime += info.totalTime;
			last.viewMatrix = removeDuplicates([...last.viewMatrix, ...info.viewMatrix]);
			last.videoLength = vLength;
		}

		return grouped;
	}, {});

	Object.keys(viewsOld).map(k => {
		viewsOld[k].ranges = matrixToRanges(viewsOld[k].viewMatrix, viewsOld[k].videoLength);
	});



	// Raggruppa i log nuovi (quelli con le ranges)
	let viewsNew = logsNew
	.reduce((grouped, log) => {
		const info = log.info;
		const totTime = getTotalRangesTime(info.ranges);
		let last = grouped[info.videoId];

		if (!last) {
			grouped[info.videoId] = {
				views: [{ id: info.viewId, time: totTime }],
				id: info.videoId,
				title: `${videos[info.videoId].title} [ID: ${info.videoId}]`,
				ranges: info.ranges,
				videoLength: info.videoLength,
				showPopover: true
			}
		}
		else {
			const lastView = last.views.find(v => v.id === info.viewId);

			if (lastView) {
				lastView.time = totTime > lastView.time? totTime : lastView.time;
			}
			else {
				last.views.push({ id: info.viewId, time: totTime });
			}

			last.videoLength = info.videoLength;
			last.ranges = [...last.ranges, ...info.ranges];
		}

		return grouped;
	}, {});

	Object.keys(viewsNew).map(k => {
		viewsNew[k].ranges = mergeRanges(viewsNew[k].ranges);
		viewsNew[k].totalTime = viewsNew[k].views.reduce((tot, v) => v.time + tot, 0);
		viewsNew[k].views = viewsNew[k].views.length;
	});



	// Merge dei video in cui ci sono sia log vecchi che nuovi
	const vMerged = Object.keys(viewsNew)
	.filter(k => viewsOld[k])
	.map(k => {
		const oldV = viewsOld[k];
		const newV = viewsNew[k];

		return {
			id: newV.id,
			views: oldV.views + newV.views,
			title: newV.title,
			totalTime: oldV.totalTime + newV.totalTime,
			videoLength: newV.videoLength,
			ranges: mergeRanges(oldV.ranges, newV.ranges),
			showPopover: false
		}
	});


	
	const views = [
		// Solo vecchi
		...Object.keys(viewsOld)
		.filter(k => !viewsNew[k])
		.map(k => viewsOld[k]),

		// Misti mergiati
		...vMerged,
		
		// Solo nuovi
		...Object.keys(viewsNew)
		.filter(k => !viewsOld[k])
		.map(k => viewsNew[k])
	];

	return views;
}

const groupLogs = logs => {
	const logsGrouped = [];

	logs.map(l => {
		if (l.info.viewId) {
			const lastIndex = logsGrouped.findIndex(lg => lg.info.viewId == l.info.viewId && lg.info.videoId == l.info.videoId);
			if (lastIndex !== -1) {
				logsGrouped[lastIndex].info.ranges = mergeRanges([...logsGrouped[lastIndex].info.ranges, ...l.info.ranges]);
				if (stringToDate(logsGrouped[lastIndex].time).valueOf() < stringToDate(l.time).valueOf()) {
					logsGrouped[lastIndex].time = l.time;
				}

				return;
			}
		}

		logsGrouped.push(l);
	});

	return logsGrouped;
}


// ##### LOGS #####
const displayInfo = (log, video) => {
	switch (log.tag)
	{
		case "login":
			return "login eseguito";
		case "videoend":
			return `video end: ${video?.title}`;
		case "videoplay":
			return <CmpVideoProgress info = {log.info} video = {video}/>;
		default:
			return null;
	}
};

export default PgUserDetails;