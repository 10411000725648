export const googleAnalyticsTracker = (category, action, label, value) => {
	try {

		window.gtag('event', action, {
			'event_category': category,
			'event_label': label,
			'value': value
		});

	} catch (e) {

	}
}
