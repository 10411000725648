import { useSelector } from 'react-redux';
import { useEffect } from 'react'
import "./header.cmp.scss"

function Header() {
    const header = useSelector((state) => state.UI.header);

    useEffect(() => {

        if (document.querySelectorAll(".navbar-product .navbar-toggler").length > 0) {

            document.querySelector(".navbar-product .navbar-toggler").addEventListener('click', function (event) {
                document.getElementById("navbar-mobile").classList.toggle("show");
                document.getElementById("navbar-mobile").classList.toggle("collapse");
            }, false);
        }

        if (document.querySelectorAll(".navbar-product .dropdown-toggle").length > 0) {

            document.querySelectorAll(".navbar-product .dropdown-toggle").forEach((item) => {
                item.addEventListener('click', function (event) {
                    event.currentTarget.closest(".nav-item").querySelector(".dropdown-menu").classList.toggle("show");
                }, false);
            })
        }

    }, [header])

    return <div dangerouslySetInnerHTML={{ __html: header }} />;
}

export default Header;