import { createSlice } from "@reduxjs/toolkit";

// const nullVideoProgess = (id = "") => ({
//     info: {
//         videoId: id,
//         viewMatrix: [],
//         totalTime: 0
//     },
//     status: "success",
//     tag: "videoplay",
// 	videoEnded: false,
// 	timeOffset: 0
// });

const nullVideoProgess = () => ({
    info: {
        videoId: "",
		viewId: null,
		ranges: [],
		videoLength: 0
    },
    status: "success",
    tag: "videoplay",
	videoEnded: false,
	paused: false
});

export const getTotalRangesTime = ranges => ranges.reduce((duration, r) => duration += r[1] - r[0], 0);
export const mergeRanges = ranges => {
	if (!ranges?.length) { return []; }
	if (ranges.length == 1) { return ranges; }
	
	ranges.sort((a, b) => a[0] - b[0]);

	let last;
	const final = ranges.reduce((final, r, i, arr) => {
		if (!last) {
			last = [...r];
			return final;
		}

		if (last[1] < r[0]) {
			final.push(last);
			last = [...r];
		}
		else { last[1] = Math.max(last[1], r[1]); }

		if (i == arr.length - 1) { final.push(last); }
		return final;
	}, []);

	return final;
};

export const matrixToRanges = (matrix, videoLength) => {
	if (matrix.length < 2) { return []; } // Spero che possa solo essere vuoto o 0

	let last;
	let final = matrix
	.slice(1) // Togli 0 che non ci importa
	.reduce((final, entry, i, arr) => {
		if (!last) {
			last = [entry, entry];
			if (arr.length == 1) return [last];
			return final;
		}

		if (last[1] + 1 == entry) { last[1] = entry; }
		else { 
			final.push(last);
			last = [entry, entry];
		}

		if (i == arr.length - 1) { final.push(last); }
		return final;
	}, []);

	final = final.map(r => {
		return [Math.floor((r[0] - 1) * videoLength) / 100, Math.ceil(r[1] * videoLength) / 100];
	});

	return final;
};

export const getPercentViewTime = (video, viewTime) => Math.floor((viewTime / getVideoDurationMs(video)) * 100)

export const getVideoDurationMs = (video) => {
    const dur = video.duration.split(":");
    return (parseInt(dur[0] * 60) + parseInt(dur[1])) * 1000;
}

export const toSerializableVideoElement = vElement => {
	const ranges = vElement.played;

	return {
		ranges: [...Array(ranges.length).keys()]
		.map(i => [
			Math.floor(ranges.start(i) * 1000),
			Math.floor(ranges.end(i) * 1000)
		]),

		duration: Math.floor(vElement.duration * 1000)
	}; 
};

// const calcTotalTime = (totDuration, viewMatrix) => Math.floor((viewMatrix.length - 1) * 0.01 * totDuration);



const VideoSlice = createSlice({
    name: "video",
    initialState: {
        currVideo: null,
        videoProgress: nullVideoProgess()
    },
    reducers: {
        reset: (state) => {
            state.currVideo = null;
            state.videoProgress = nullVideoProgess();
        },
		
        // videoPlay(video)
        videoPlay: (state, action) => {
            const video = action.payload;

            if (!video) return;
            if (!state.currVideo || video.videoId !== state.currVideo.videoId)
            {
                state.currVideo = video;
                state.videoProgress = nullVideoProgess();
				state.videoProgress.info = {
					videoId: video.videoId,
					viewId: `${Date.now()}`,
					ranges: [],
					videoLength: 0
				}
            }
        },

        // videoProgress(timestamp) # in millisecondi
        // videoProgress: (state, action) => {
        //     if (!state.currVideo) { return console.error("No video selected"); }
        //     const timestamp = action.payload * 1000;
        //     const video = state.currVideo;
        //     const progress = state.videoProgress;

        //     const durMs = getVideoDurationMs(video);

        //     // Calc percentile
        //     const matrix = progress.info.viewMatrix;
        //     const matrixEntry = Math.floor((timestamp * 100) / durMs);
		// 	progress.info.totalTime = Math.floor(timestamp) - progress.timeOffset;
            
        //     // Push percentile
        //     if (matrix.indexOf(matrixEntry) === -1) {
        //         progress.info.viewMatrix.push(matrixEntry);
        //         progress.info.viewMatrix.sort((a, b) => a - b);
        //     }

        //     state.videoProgress = { ...progress };
        // },
		
		// videoProgress(videoElement)
		videoProgress: (state, action) => {
			if (!state.currVideo) { return console.error("No video selected"); }
			state.videoProgress.info.ranges = action.payload.ranges;
			state.videoProgress.info.videoLength = action.payload.duration;
		},

		// videoTogglePause(paused)
		videoTogglePause: (state, action) => {
			state.videoProgress.paused = action.payload;
		},

        videoEnd: (state) => {
            if (!state.currVideo) { return console.error("No video selected"); }
			state.videoProgress.videoEnded = true;
        }
    }
});

export const { reset, videoPlay, videoProgress, videoTogglePause, videoEnd } = VideoSlice.actions;
export default VideoSlice.reducer;