import { useDispatch } from "react-redux";
import { login as storeLogin, logout as storeLogout } from "../store/features/UserSlice";
import { reset as resetVideo } from "../store/features/VideoSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useSessionStorage from "./useSessionStorage.hk";
import { googleAnalyticsTracker } from "../utils/google";
import { login } from "../service"

// TODO nome prop da config?
// {sPropName = "_bw6_user_data"}
const useLogin = () => {
	const user = useSelector(state => state.user.userData);
	const dispatch = useDispatch();
	const [, setSSUser] = useSessionStorage("_bw6_user_data");

	const execLogin = (name, pw) => {

		return login(name, pw)
			.then(res => {
				const { status, ...usr } = res.data;
				if (status === "OK") {
					googleAnalyticsTracker("page", "login", usr.username);
					dispatch(storeLogin(usr));
					dispatch(resetVideo());
				}
				throw res.data;
			}).catch(err => err);
	}

	const setUserManually = (user) => {
		dispatch(storeLogin(user));
	};

	useEffect(() => {
		setSSUser(user);
	}, [setSSUser, user]);

	const logout = () => {
		dispatch(storeLogout());
		dispatch(resetVideo());
		setSSUser(null, true);
	};

	return [user, execLogin, logout, setUserManually];
};

export default useLogin;