import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setHTML } from '../store/features/UISlice';
import { setAppConfig } from '../store/features/AppConfig';
import { getAppConfig } from '../service';
import { setPrefixPageTitle } from '../utils/page';

export const useAppConfig = () => {
	const dispatch = useDispatch();
	// const sUi = useSelector(state => state.UI);
	// const sAppConfig = useSelector(state => state.AppConfig);

	const sUi = useSelector(state => state.UI);

	useEffect(() => {
		if (!sUi?.loaded) {
			getAppConfig().then(res => {

				if (res.status === 200 && res.data.status === "OK") {

					dispatch(setAppConfig({
						host: res.data.host,
						web: res.data.web,
						site: res.data.site,
						googleAnalytics: res.data.googleAnalytics,
						name: res.data.projectName
					}));

					setPrefixPageTitle(res.data.projectName);

					let tempHtml = res.data.html;
					let header = "";
					let footer = "";

					if (tempHtml != "") {

						let tempHtml = res.data.html;
						tempHtml = tempHtml.replace(/ src=/g, " temp-data-src=");
						tempHtml = tempHtml.replace(/ href=/g, " temp-data-href=");

						const htmlBuffer = document.createElement("html");
						htmlBuffer.innerHTML = tempHtml;

						if(res.data.site.toLowerCase().indexOf("www.eduplanweb.it") !== -1) {
							header = htmlBuffer.querySelector("nav.navbar-product.navbar-eduplanweb").outerHTML;
							header = header.replace(/\\"/g, '"');
							header = header.replace(/ temp-data-src=/g, " src=");
							header = header.replace(/ temp-data-href=/g, " href=");
							header = header.replace(/#/g, 'javascript:void();');
							header = header.replace(/\/archivi\/CGSI\//g, 'https://www.eduplanweb.it/archivi/CGSI/');
							header = header.replace(/href="\//g, 'href="https://www.eduplanweb.it/');
							//
							footer = htmlBuffer.querySelector("footer").outerHTML;
							footer = footer.replace(/\\"/g, '"');
							footer = footer.replace(" temp-data-src=", " src=");
							footer = footer.replace(" temp-data-href=", " href=");
							footer = footer.replace(/<script.*?<\/script>/g, '');
							footer = footer.replace(/\/archivi\/CGSI\//g, 'https://www.eduplanweb.it/archivi/CGSI/');
							footer = footer.replace(/href="\//g, 'href="https://www.eduplanweb.it/');
							footer = footer.replace(/src="\//g, 'src="https://www.eduplanweb.it/');
						
						}else if(res.data.site.toLowerCase().indexOf("www.eplanweb.it") !== -1) {
							header = htmlBuffer.querySelector("nav.navbar-product.navbar-eduplanweb").outerHTML;
							header = header.replace(/\\"/g, '"');
							header = header.replace(/ temp-data-src=/g, " src=");
							header = header.replace(/ temp-data-href=/g, " href=");
							header = header.replace(/#/g, 'javascript:void();');
							header = header.replace(/\/archivi\/CGSI\//g, 'https://www.eplanweb.it/archivi/CGSI/');
							header = header.replace(/href="\//g, 'href="https://www.eplanweb.it/');
							//
							footer = htmlBuffer.querySelector("footer").outerHTML;
							footer = footer.replace(/\\"/g, '"');
							footer = footer.replace(" temp-data-src=", " src=");
							footer = footer.replace(" temp-data-href=", " href=");
							footer = footer.replace(/<script.*?<\/script>/g, '');
							footer = footer.replace(/\/archivi\/CGSI\//g, 'https://www.eplanweb.it/archivi/CGSI/');
							footer = footer.replace(/href="\//g, 'href="https://www.eplanweb.it/');
							footer = footer.replace(/src="\//g, 'src="https://www.eplanweb.it/');

						}else if(res.data.site.toLowerCase().indexOf("www.sicapweb.net") !== -1) {
							header = htmlBuffer.querySelector("nav.navbar-product.navbar-sicap").outerHTML;
							header = header.replace(/\\"/g, '"');
							header = header.replace(/ temp-data-src=/g, " src=");
							header = header.replace(/ temp-data-href=/g, " href=");
							header = header.replace(/#/g, 'javascript:void();');
							header = header.replace(/\/archivi\/CGSI\//g, 'https://www.sicapweb.net/archivi/CGSI/');
							header = header.replace(/href="\//g, 'href="https://www.sicapweb.net/');
							//
							footer = htmlBuffer.querySelector("footer").outerHTML;
							footer = footer.replace(/\\"/g, '"');
							footer = footer.replace(" temp-data-src=", " src=");
							footer = footer.replace(" temp-data-href=", " href=");
							footer = footer.replace(/<script.*?<\/script>/g, '');
							footer = footer.replace(/\/archivi\/CGSI\//g, 'https://www.sicapweb.net/archivi/CGSI/');
							footer = footer.replace(/href="\//g, 'href="https://www.sicapweb.net/');
							footer = footer.replace(/src="\//g, 'src="https://www.sicapweb.net/');

						}else if(res.data.site.toLowerCase().indexOf("www.bibliowin.it") !== -1) {
							header = htmlBuffer.querySelector("nav.navbar-product.navbar-bibliowin").outerHTML;
							header = header.replace(/\\"/g, '"');
							header = header.replace(/ temp-data-src=/g, " src=");
							header = header.replace(/ temp-data-href=/g, " href=");
							header = header.replace(/#/g, 'javascript:void();');
							header = header.replace(/\/archivi\/CGSI\//g, 'https://www.bibliowin.it/archivi/CGSI/');
							header = header.replace(/href="\//g, 'href="https://www.bibliowin.it/');
							//
							footer = htmlBuffer.querySelector("footer").outerHTML;
							footer = footer.replace(/\\"/g, '"');
							footer = footer.replace(/ temp-data-src=/g, " src=");
							footer = footer.replace(/ temp-data-href=/g, " href=");
							footer = footer.replace(/<script.*?<\/script>/g, '');
							footer = footer.replace(/\/archivi\/CGSI\//g, 'https://www.bibliowin.it/archivi/CGSI/');
							footer = footer.replace(/href="\//g, 'href="https://www.bibliowin.it/');
							footer = footer.replace(/src="\//g, 'src="https://www.bibliowin.it/');

						}else if(res.data.site.toLowerCase().indexOf("www.cgsi.it") !== -1) {
							header = htmlBuffer.querySelector("nav.navbar-global").outerHTML;
							header = header.replace(/\\"/g, '"');
							header = header.replace(/ temp-data-src=/g, " src=");
							header = header.replace(/ temp-data-href=/g, " href=");
							header = header.replace(/#/g, 'javascript:void();');
							header = header.replace(/\/archivi\/CGSI\//g, 'https://www.cgsi.it/archivi/CGSI/');
							header = header.replace(/href="\//g, 'href="https://www.cgsi.it/');
							//
							footer = htmlBuffer.querySelector("footer").outerHTML;
							footer = footer.replace(/\\"/g, '"');
							footer = footer.replace(/ temp-data-src=/g, " src=");
							footer = footer.replace(/ temp-data-href=/g, " href=");
							footer = footer.replace(/<script.*?<\/script>/g, '');
							footer = footer.replace(/\/archivi\/CGSI\//g, 'https://www.cgsi.it/archivi/CGSI/');
							footer = footer.replace(/href="\//g, 'href="https://www.cgsi.it/');
							footer = footer.replace(/src="\//g, 'src="https://www.cgsi.it/');
						}
					}

					dispatch(setHTML({
						header: header,
						footer: footer,
						loaded: true,
					}));
				}
			});
		}
	}, []);
}