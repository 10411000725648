import { createPortal } from 'react-dom';
import { React } from "react";
import { useSelector } from "react-redux";
import { useModal } from '../hooks/useModal.hk';

const Modal = () => {  
  const modal = useSelector(state => state.modal); 
  const modalRoot = document.body;
  let { hideModal } = useModal();

  if (modal.show) {
    return createPortal(
      <>
      <div className="modal fade show" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true" style={{display:"block"}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel" dangerouslySetInnerHTML={{__html: modal.title}}></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hideModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body"  dangerouslySetInnerHTML={{__html: modal.content}}>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => hideModal()}>Close</button>
              {/* <button type="button" className="btn btn-primary">Understood</button> */}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show" onClick={() => hideModal()}></div>
      </>,
      modalRoot
    );
  } else return null;
};

export { Modal };