import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function GoogleAnalytics() {

    const appConfig = useSelector(state => state.AppConfig);
    useEffect(() => {
        if (appConfig.googleAnalytics) {
            const script1 = document.createElement('script');
            script1.type = 'text/javascript';
            script1.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.googleAnalytics}`;
            script1.innerHTML = `window.dataLayer = window.dataLayer || [];
              window.gtag = function() {window.dataLayer.push(arguments);}
              window.gtag('js', new Date());
              window.gtag('config', '${appConfig.googleAnalytics}');
              `;
            document.body.appendChild(script1);
            //
            const script2 = document.createElement('script');
            script2.type = 'text/javascript';
            script2.innerHTML = `window.dataLayer = window.dataLayer || [];
              window.gtag = function() {window.dataLayer.push(arguments);}
              window.gtag('js', new Date());
              window.gtag('config', '${appConfig.googleAnalytics}');
              `;
            document.body.appendChild(script2);
        }
	}, []);

    return "";
}
export default GoogleAnalytics;
