/*
Funzione usata per cambiare il title della pagina html a seconda della sezione in cui ci si trova
Da usare dopo che è stato definito il prefisso del titolo, vedi funzione setPrefixPageTitle
*/
export const setPageTitle = (section) => {
	let title = "";
	title = "";
	// Estraggo il prefix
	try{
		title = document.querySelector("head title").innerHTML.split("|")[0];
		title += " | ";
	}catch(e) {

	}
	// ed inserisco la sezione in cui mi trovo
	title += section;
	document.querySelector("head title").innerHTML = title;
}

/* 
	Usato una sola volta al caricamento della app per dare il prefisso al titolo della pagina: BIBLIOWin | oppure EDUPLan | 
	prefix è la variabile projectName che proviene da domains.xml
*/
export const setPrefixPageTitle = (prefix) => {
	document.querySelector("head title").innerHTML = prefix + " | ";
}