import { createSlice } from "@reduxjs/toolkit";

const appConfig = {
	host: "",
	web: "",
	site: "",
    googleAnalytics: "",
    name: "",
};

const AppConfigSlice = createSlice({
    name: "AppConfig",
    initialState: appConfig,
    reducers: {
        setAppConfig: (state, action) => {
            state.host = action.payload.host;
            state.web = action.payload.web;
            state.site = action.payload.site;
            state.googleAnalytics = action.payload.googleAnalytics;
            state.name = action.payload.name;
        }
    }
});

export const { setAppConfig } = AppConfigSlice.actions;
export default AppConfigSlice.reducer;