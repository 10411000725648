import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import CmpDataTable from "../../components/dataTable.cmp";
import { DataTableCol } from "../../components/dataTable.cmp";
import CmpVideoProgress from "../../components/videoProgress.cmp";
import useLogin from "../../hooks/useLogin.hk";
import { getLoggedUsers } from "../../service";
import { stringToDate } from "../../utils/date";
import { setPageTitle } from "../../utils/page";

import "./users.pg.scss";

const emptyState = () => ({
	logUsers: [],
	users: {},
	videos: {},
});

const colsUsers = state => [
	DataTableCol("user", "User", lug => {
		const user = state.users[lug.logUser.id];
		return user? <>
			<Link className = "log-user-link mr-3" to = {`/users/${user.id}`}>{user.username}</Link>
			<div>
				<FontAwesomeIcon icon = "sign-in-alt" title = "Login" className = "mr-1"/>{lug.numLogins}
				<FontAwesomeIcon icon = "eye" title = "Visualizzazioni" className = "ml-3 mr-1"/>{lug.numViews}
			</div>
			<span className = "log-user-type">{user.type}</span>
		</> : "[eliminato]";
	}),
	DataTableCol("cDate", "Created", lug => state.users[lug.logUser.id].creationDate),
	DataTableCol("time", "Last activity", lug => lug.logUser.time),
	DataTableCol("tag", "Tag", lug => <div className = "tag">{lug.logUser.tag}</div>),
	DataTableCol("info", "Info", lug => displayInfo(lug.logUser, state.videos[lug.logUser.info.videoId]))
]

// Dichiaro tutti i possibili filtri
// Devo passargli anche lo stato sennò rimane in canna quello vecchio
// es. currState => lUser => currState.users[lUser.id]?.type == "FULL"\
// es. currState => luGrouped => luGrouped.num > 100
const FILTERS = {
	fltType: (type => currState => luG => type? currState.users[luG.logUser.id]?.type == type : true),
	fltExpired: (expired => currState => luG => {
		const user = currState.users[luG.logUser.id];
		if (!user) { return expired === ""; }
		return expired === ""? true : ((stringToDate(user.expirationDate, false).valueOf() < new Date().valueOf()).toString() === expired)
	}),

	fltActivity: (nDays => currState => lUser =>
		nDays? stringToDate(lUser.time).getTime() >= Date.now() - (nDays * 86400000) : true), // 86400000 millisecondi in un giorno

	fltSearch: (search => currState => luG => (currState.users[luG.logUser.id]?.username || "").toLowerCase().indexOf(search.toLowerCase()) !== -1),
};

const SORTS = {
	srtNumIfActivity: (nDays => currState => nDays?
		((lug1, lug2) => lug2.num - lug1.num) :
		((lug1, lug2) => 0)),
	
	srtLastActivity: (enabled => currState => enabled?
		(lug1, lug2) => stringToDate(lug2.logUser.time).valueOf() - stringToDate(lug1.logUser.time).valueOf() :
		() => 0),

	srtCreationDate: (enabled => currState => enabled?
		(lug1, lug2) => {
			const d1 = (currState.users[lug1.logUser.id]?.creationDate || "");
			const d2 = (currState.users[lug2.logUser.id]?.creationDate || "");
			return stringToDate(d2, false).valueOf() - stringToDate(d1, false).valueOf();
		} :
		() => 0),
};

const PgUserLogs = () => {
	const history = useHistory();
	const [currUser,, logout] = useLogin();
	const [state, setState] = useState(emptyState());

	// Filtri / ricerche ecc.
	const [uType, setUType] = useState("");
	const btnClassByType = type => `btn btn-${type === uType? "primary" : "light"}`;
	
	const [search, setSearch] = useState("");
	const [nDays, setNDays] = useState(0);
	const [expired, setExpired] = useState("");
	const [sortCDate, setSortCDate] = useState(false);

	const filters = {
		beforeGroup: [
			FILTERS.fltActivity(nDays)
		],
		afterGroup: [
			FILTERS.fltType(uType),
			FILTERS.fltExpired(expired),
			FILTERS.fltSearch(search),
		]
	};

	const sorts = {
		beforeGroup: [],
		afterGroup: [
			SORTS.srtNumIfActivity(nDays),
			SORTS.srtLastActivity(!nDays),
			SORTS.srtCreationDate(sortCDate == "true")
		]
	};

	useEffect(() => {
		setPageTitle("Log utenti");
	});



	// Chiamata per prendermi i logUsers
	useEffect(() => {
		getLoggedUsers(currUser.token)
		.then(res => {
			if (res.data.errorMsg === "token expired") { logout(); return; }

			if (res.data.status === "OK") {
				let newState = emptyState();

				// Uso oggetti perché altrimenti con gli array dovrei cercare ogni volta
				res.data.videos.map(v => newState.videos[v.videoId] = v);
				res.data.users.map(u => newState.users[u.id] = u);

				let logUsers = res.data.logUsers
				// Tolgo quelli con id utente non valido
				.filter(lu => newState.users[lu.id])
				// Parsing del campo info che a volte è un JSON a volte no
				.map(lu => {
					try { lu.info = JSON.parse(lu.info); }
					catch(e) {}

					return lu;
				});

				// Trasformo in array
				newState.logUsers = Object.values(logUsers);
				setState(newState);
			}
			else { /* Mostrare errore */ }
		});
	}, []);



	// Filtri / sort
	let lUsersGrouped = [...state.logUsers];
	filters.beforeGroup.map(f => lUsersGrouped = lUsersGrouped.filter(f(state)));
	sorts.beforeGroup.map(f => lUsersGrouped = lUsersGrouped.sort(f(state)));
	
	// Grouping per utente di tutti i logUser, mostro solo i più recenti
	lUsersGrouped = lUsersGrouped.reduce((lastLogUsers, lu) => {
		// lastLogUsers ha per ogni utente la sua ultima attività e il numero di attività loggate
		const last = lastLogUsers[lu.id] || {
			logUser: lu,
			num: 0,
			numLogins: 0,
			numViews: 0,
			viewIds: []
		};
		
		if (lastLogUsers[lu.id] === undefined) {
			lastLogUsers[lu.id] = last;
		}

		// Se è più recente sostituisco
		if (stringToDate(last.logUser.time).getTime() <= stringToDate(lu.time).getTime()) {
			lastLogUsers[lu.id].logUser = lu;
		}

		// lastLogUsers[lu.id].num++;

		if (lu.tag == "login") {
			lastLogUsers[lu.id].numLogins++;
			lastLogUsers[lu.id].num++;
		}
		else if (lu.tag == "videoplay") {
			lastLogUsers[lu.id].num++;

			const viewId = lu.info.viewId;
			if (!viewId) {
				lastLogUsers[lu.id].numViews++;
				return lastLogUsers;
			}

			if (lastLogUsers[lu.id].viewIds.indexOf(viewId) === -1) {
				lastLogUsers[lu.id].viewIds.push(viewId);
			}
			else {}
		}
		
		return lastLogUsers;
	}, {})
	// Trasformo l'oggetto risultante in array aggiornando il numero di views
	lUsersGrouped = Object.keys(lUsersGrouped).map(key => {
		lUsersGrouped[key].numViews += lUsersGrouped[key].viewIds.length;
		return lUsersGrouped[key];
	});
	
	lUsersGrouped.map(lug => console.log(state.users[lug.logUser.id].creationDate + " | " + state.users[lug.logUser.id].username));
	console.log("#####")

	// Filtri / sort
	filters.afterGroup.map(f => lUsersGrouped = lUsersGrouped.filter(f(state)));
	sorts.afterGroup.map(f => lUsersGrouped = lUsersGrouped.sort(f(state)));



	return <div className = "pg-users">
		<div className = "users-container">
			<div className = "d-flex">
				<h1 className = "title mb-0 mr-auto">Utenti</h1>
				<button className = "videos-btn" onClick = {() => history.push("/videos")}>
					Torna ai video
					<FontAwesomeIcon icon = "video" className = "ml-2"/>
				</button>
			</div>

			<hr/>

			<div className = "users-menu">
				<div className = "row">

					<div className = "col-6 col-md-auto">
						<div className = "row align-items-center mb-3">
							<div className = "col-12 col-md-auto pr-md-2 mb-2 mb-md-0">Visualizza utenti</div>
							<div className = "col-12 col-md-auto px-md-2">
								<div className = "users-btns btn-group btn-group-toggle">
									<button className = {btnClassByType("")} onClick = {() => setUType("")}>Tutti</button>
									<button className = {btnClassByType("FULL")} onClick = {() => setUType("FULL")}>Full</button>
									<button className = {btnClassByType("DEMO")} onClick = {() => setUType("DEMO")}>Demo</button>
								</div>
							</div>
						</div>
					</div>

					<div className = "col-6 col-md-auto ml-auto mb-md-3">
						<div className = "row align-items-center">
							<div className = "col-12 col-md-auto px-md-2 mb-2 mb-md-0">
								<div className = "position-relative">
									<input className = "users-search form-control" value = {search} onChange = {event => setSearch(event.target.value)} placeholder = "Ricerca utente..."/>
									<button className = "users-search-clear" onClick = {() => setSearch("")}>
										<FontAwesomeIcon icon = "times"/>
									</button>
								</div>
							</div>
							<div className = "col-12 col-md-auto px-md-2 mb-2 mb-md-0">
								<select className = "users-expired form-control" value = {expired} onChange = {event => setExpired(event.target.value)}>
									<option value = {""}>Per scadenza...</option>
									<option value = {true}>Scaduti</option>
									<option value = {false}>Non scaduti</option>
								</select>
							</div>
							<div className = "col-12 col-md-auto px-md-2 mb-2 mb-md-0">
								<select className = "users-creation-date form-control" value = {sortCDate} onChange = {event => setSortCDate(event.target.value)}>
									<option value = {false}>Per ultima attività</option>
									<option value = {true}>Per data creazione</option>
								</select>
							</div>
							{/* <div className = "col-12 col-md-auto px-md-2 mb-2 mb-md-0">
								<button onClick = {() => setSortCDate(!sortCDate)} className = "checkbox users-creation-date">
									<div className = "form-control d-flex align-items-center justify-content-between">
										<label>Ordina per data creazione</label>
										<FontAwesomeIcon className = "ml-2" icon = {sortCDate? "check-square" : "square"}/>
									</div>
								</button>
							</div> */}
							<div className = "col-12 col-md-auto pl-md-2 mb-3 mb-md-0">
								<select className = "users-activity form-control" value = {nDays} onChange = {event => setNDays(parseInt(event.target.value))}>
									<option value = {0}>Utenti più attivi...</option>
									<option value = {30}>Ultimi 30 giorni</option>
									<option value = {60}>Ultimi 60 giorni</option>
									<option value = {90}>Ultimi 90 giorni</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<hr className = "mt-0"/>
				Trovati: {lUsersGrouped.length}
			</div>

			<CmpDataTable className = "users-table" data = {lUsersGrouped} rowKeyFn = {lug => lug.logUser.id} columns = {colsUsers(state)}/>
		</div>
	</div>
};

const displayInfo = (logUser, video) => {
	switch (logUser.tag)
	{
		case "login":
			return <b>login eseguito</b>;
		case "videoend":
			return <b>video end: {video?.title}</b>;
		case "videoplay":
			return <CmpVideoProgress info = {logUser.info} video = {video}/>;
		default:
			return null;
	}
};

export default PgUserLogs;